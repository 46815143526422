import { ParticipantRole } from "@aws-sdk/client-connect";
import { Item } from "@aws-sdk/client-connectparticipant";
import { AGENT_AISHA_MCKAY_MOCK } from "src/common/mocks/agent";
import {
  ChatParticipantAgent,
  ChatParticipantCustomer,
  ChatParticipantType,
} from "src/common/types";
import { logMock } from "src/common/utils/log-mock";

export function deserializeAWSUser(item: Item) {
  switch (item.ParticipantRole) {
    case ParticipantRole.AGENT:
    case ParticipantRole.SYSTEM:
      return {
        avatar: logMock(AGENT_AISHA_MCKAY_MOCK.avatar),
        displayName: logMock(AGENT_AISHA_MCKAY_MOCK.displayName),
        id: item.Id,
        firstName: logMock(AGENT_AISHA_MCKAY_MOCK.firstName),
        lastName: logMock(AGENT_AISHA_MCKAY_MOCK.lastName),
        role: logMock(AGENT_AISHA_MCKAY_MOCK.role),
        type: ChatParticipantType.AGENT,
      } as ChatParticipantAgent;
    case ParticipantRole.CUSTOMER:
      return {
        avatar: null,
        displayName: item.DisplayName,
        id: item.Id,
        firstName: null,
        lastName: null,
        role: "Customer",
        type: ChatParticipantType.CUSTOMER,
      } as ChatParticipantCustomer;
    default:
      return null;
  }
}
