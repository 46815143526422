import { Item } from "@aws-sdk/client-connectparticipant";
import { ChatItem } from "src/common/types";
import { deserializeAWSUser } from "./awsUser";
import { deserializeParticipantRole } from "./participantRole";

export function deserializeAwsChatItem(responseObject: Item) {
  const {
    AbsoluteTime: createdAt,
    Content: content,
    DisplayName: displayName,
    Id: id,
    ContactId: contactId,
    ParticipantId: participantId,
    ParticipantRole: participantRole,
    Type: type,
  } = responseObject;

  if (!content || !createdAt || !participantId || !id || !type) return null;

  const user = deserializeAWSUser(responseObject);

  if (!user) return null;

  const result: ChatItem = {
    contactId: contactId ?? null,
    content,
    createdAt,
    id,
    participantId,
    participantRole: deserializeParticipantRole(participantRole),
    type,
    user,
  };

  return result;
}
