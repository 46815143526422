"use client";

import {
  GetTranscriptResponse,
  Item,
} from "@aws-sdk/client-connectparticipant";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { ApiResult, fetcher } from "src/api/clients/api";
import { deserializeAwsChatItem } from "src/api/serializers/awsChatItem";
import { CLIENT_ENDPOINTS } from "../config/client-endpoints";
import { COOKIES_KEYS } from "../config/cookies";
import { AmazonConnectConfig, ChatItem } from "../types";
import {
  ChatSession,
  ConnectError,
  OnConnectionEstablishedEvent,
  OnMessageEvent,
  TypingEvent,
} from "../types/aws";
import { AmazonConnectConfig as BackendAmazonConnectConfig } from "../types/backend";
import { getBackendBaseUrl } from "../utils/get-backend-base-url";
import { getIsAuthenticated } from "../utils/get-is-authenticated";
import { getAuthorizationHeader } from "../utils/helpers";
import { debounce } from "../utils/debounce";
import { logChat } from "../utils/log-chat";
import cookies from "src/common/utils/cookies";
import { TRANSLATION } from "../config/translation";

export enum ChatState {
  CHAT_ACTIVE = "active",
  CHAT_ENDED = "ended",
  CHAT_IDLE = "idle",
  ERROR = "error",
  FORM = "form",
  LOADING = "loading",
}

export interface ChatWidgetUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface ChatContextType {
  chatState: ChatState;
  chatTranscript: ChatItem[];
  endChat: () => Promise<void>;
  isLoading: boolean;
  isOpen: boolean;
  isTyping: boolean;
  openChat: () => Promise<void>;
  restartEndedChat: () => Promise<void>;
  sendMessage: (message: string) => Promise<void>;
  sendTypingEvent: () => Promise<void>;
  serverError: string | null;
  setChatError: (error: unknown) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const EMPTY_CHAT_CONTEXT: ChatContextType = {
  chatState: ChatState.FORM,
  chatTranscript: [],
  endChat: async () => {},
  isOpen: false,
  isLoading: false,
  isTyping: false,
  openChat: async () => {},
  restartEndedChat: async () => {},
  sendMessage: async () => {},
  sendTypingEvent: async () => {},
  serverError: null,
  setChatError: () => {},
  setIsLoading: () => {},
  setIsOpen: () => {},
};

export const ChatContext = createContext<ChatContextType>(EMPTY_CHAT_CONTEXT);

export function getHasChatUser() {
  const chatUser = getChatUser();

  const hasChatUser =
    !!chatUser?.email && !!chatUser?.firstName && !!chatUser?.lastName;

  return hasChatUser;
}

export function getHasToken() {
  const awsConfig = getAwsConfig();

  const hasToken =
    !!awsConfig?.contactId &&
    !!awsConfig?.participantId &&
    !!awsConfig?.participantToken;

  return hasToken;
}

export function getAwsConfig() {
  const { getCookie } = cookies();
  if (typeof window === "undefined") return null;

  const awsConfigString = getCookie(COOKIES_KEYS.CHAT_TOKEN);
  const awsConfig: AmazonConnectConfig = awsConfigString
    ? JSON.parse(awsConfigString)
    : null;

  return awsConfig;
}

export function getChatUser() {
  const { getCookie } = cookies();
  if (typeof window === "undefined") return null;

  const chatUserString = getCookie(COOKIES_KEYS.CHAT_USER);
  const chatUser: ChatWidgetUser = chatUserString
    ? JSON.parse(chatUserString)
    : null;

  return chatUser;
}

export function getHasParticipantToken() {
  if (typeof window === "undefined") return false;

  const config = getAwsConfig();

  return !!config?.participantToken;
}

export function ChatProvider({ children }: { children: ReactNode }) {
  const { getCookie, setCookie } = cookies();
  const isAuthenticated = getIsAuthenticated();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const chatSession = useRef<ChatSession | null>(null);
  const [chatState, setChatState] = useState(
    isAuthenticated ? ChatState.CHAT_IDLE : ChatState.FORM,
  );
  const [serverError, setServerError] = useState<string | null>(null);
  const [chatTranscript, dispatchChatTranscriptReducer] = useReducer(
    chatTranscriptReducer,
    [] as ChatItem[],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  function chatTranscriptReducer(
    chatTranscript: ChatItem[],
    action: any,
  ): ChatItem[] {
    switch (action.type) {
      case "reset": {
        return [] as ChatItem[];
      }
      case "add": {
        return sortAndDeduplicate([
          ...chatTranscript,
          action.chatItem,
        ]) as ChatItem[];
      }
      case "concat": {
        return sortAndDeduplicate([
          ...chatTranscript,
          ...action.chatTranscript,
        ]) as ChatItem[];
      }
      default: {
        throw Error("Unknown action: " + action.type);
      }
    }
  }

  useEffect(() => {
    if (!document) return;

    // TODO: improve handling keeping multiple tabs in sync
    function handleTabSwitch() {
      if (document.visibilityState == "visible") {
        const chatState = getCookie(COOKIES_KEYS.CHAT_REFRESH_STATE);
        !!chatState && setChatState(chatState as ChatState);
      }
    }

    document.addEventListener("visibilitychange", handleTabSwitch);
    return () =>
      document.removeEventListener("visibilitychange", handleTabSwitch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window === undefined) return;

    if (getCookie(COOKIES_KEYS.CHAT_REFRESH_STATE) === ChatState.CHAT_ACTIVE) {
      setIsOpen(true);
      openChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (chatState == ChatState.CHAT_ACTIVE && isOpen) {
        const API_URL: string = `${getBackendBaseUrl()}${
          CLIENT_ENDPOINTS.AMAZON_CONNECT_CHAT_HEALTCHECK
        }`;
        const payload = JSON.stringify({
          connection_token:
            chatSession.current?.controller.connectionDetails?.connectionToken,
        });
        const blob = new Blob([payload], { type: "application/json" });
        navigator.sendBeacon(API_URL, blob);
      }
    }, 30000);
    return () => clearInterval(intervalId);
  }, [chatState, isOpen]);

  logChat("chatState", chatState);

  async function loadPackage() {
    logChat("loadPackage");
    changeChatState(ChatState.LOADING);

    try {
      await import("amazon-connect-chatjs");
    } catch (error) {
      throw error;
    }
  }

  async function fetchToken() {
    changeChatState(ChatState.LOADING);

    try {
      const chatUser = getChatUser();
      const awsConfig = getAwsConfig();

      const API_URL: string = `${getBackendBaseUrl()}${
        CLIENT_ENDPOINTS.AMAZON_CONNECT_START_CHAT
      }`;

      const payload = isAuthenticated
        ? null
        : JSON.stringify({
            ...(awsConfig?.contactId
              ? { contact_id: awsConfig?.contactId }
              : {}),
            email: chatUser?.email ?? "",
            first_name: chatUser?.firstName ?? "",
            last_name: chatUser?.lastName ?? "",
          });

      logChat("fetchToken payload", payload);

      const { data, error } = await fetcher<
        ApiResult<BackendAmazonConnectConfig>
      >(API_URL, "POST", payload, getAuthorizationHeader());

      if (error) throw error;

      setCookie(
        COOKIES_KEYS.CHAT_TOKEN,
        JSON.stringify({
          contactId: data?.contact_id,
          lastContactId: data?.last_contact_id,
          participantId: data?.participant_id,
          participantToken: data?.participant_token,
          region: data?.region,
        } as AmazonConnectConfig),
      );

      logChat("fetchToken successful", data);
    } catch (error) {
      throw error;
    }
  }

  function handleAWSConnectError(error: unknown) {
    logChat("handleAWSConnectError", error);
    if (
      (chatState == ChatState.CHAT_ENDED || chatState == ChatState.FORM) &&
      (error as ConnectError).statusCode === 403
    ) {
      // possible caused by chat disconnection while some requests to API weren't completed
      logChat("handleAWSConnectError ignore");
      return;
    }
    throw error;
  }

  async function disconnectParticipant() {
    try {
      const response = await chatSession.current?.disconnectParticipant();
      if (response.error) throw response.error;
      logChat("disconnectParticipant", response);
    } catch (error) {
      handleAWSConnectError(error);
    }
  }

  function updateTranscript(item: Item) {
    const chatItem = deserializeAwsChatItem(item);
    if (chatItem) dispatchChatTranscriptReducer({ type: "add", chatItem });
  }

  async function endChat() {
    try {
      changeChatState(ChatState.CHAT_ENDED);
      await disconnectParticipant();
    } catch (error) {
      throw error;
    }
  }

  function setChatError(error: unknown) {
    let message: string;

    if (typeof error === "string") {
      message = error;
    } else {
      message =
        (error as ConnectError)?._debug?.reason ?? TRANSLATION.EN.GENERIC_ERROR;
    }

    setServerError(message);
    changeChatState(ChatState.ERROR);
  }

  async function createAndRetryChat() {
    try {
      await createChat();
    } catch (error) {
      console.error(error);

      if ((error as ConnectError)?.connectSuccess === false) {
        const awsConfig = getAwsConfig();
        const contactId = awsConfig?.contactId;
        setCookie(COOKIES_KEYS.CHAT_TOKEN, JSON.stringify({ contactId }));
        try {
          await fetchToken();
          await createChat();
        } catch {
          handleAWSConnectError(error);
        }
      } else {
        throw error;
      }
    }
  }

  async function createChat() {
    logChat("createChat");
    changeChatState(ChatState.LOADING);

    // TODO handle chat creation when exiting and returning to Messages page
    // currently it duplicates the event listeners causing chat message duplication

    try {
      const ChatSession = window.connect.ChatSession;

      if (!ChatSession) return;

      const awsConfig = getAwsConfig();

      if (!awsConfig?.contactId) throw "Missing contact id";

      chatSession.current = await ChatSession.create({
        chatDetails: {
          ContactId: awsConfig.contactId,
          ParticipantId: awsConfig.participantId,
          ParticipantToken: awsConfig.participantToken,
        },
        options: {
          region: awsConfig.region,
        },
        type: "CUSTOMER",
      });

      if (!chatSession.current) return;

      await chatSession.current.connect();

      chatSession.current.onConnectionEstablished(
        (event: OnConnectionEstablishedEvent) => {
          const { chatDetails } = event;
          logChat("onConnectionEstablished", { chatDetails });
        },
      );

      chatSession.current.onMessage(({ data }: OnMessageEvent) => {
        updateTranscript(data);
      });

      chatSession.current.onTyping((typingEvent: TypingEvent) => {
        logChat("onTyping");

        if (typingEvent.data.ParticipantRole === "AGENT") {
          setIsTyping(true); // TODO: debug because buggy
          debounce(() => setIsTyping(false), 2000);
        }
      });

      chatSession.current.onConnectionBroken((event: any) => {
        const { chatDetails } = event;
        logChat("onConnectionBroken", { chatDetails });
      });

      chatSession.current.onEnded(async (event: any) => {
        const { chatDetails, data } = event;
        logChat("onEnded", { chatDetails, data });

        try {
          changeChatState(ChatState.CHAT_ENDED);
        } catch (error) {
          throw error;
        }
      });

      changeChatState(ChatState.CHAT_ACTIVE);
    } catch (error) {
      throw error;
    }
  }

  function sortAndDeduplicate(transcript: ChatItem[]) {
    const deduplicated = transcript.reduce(
      (acc: ChatItem[], item: ChatItem) => {
        const { createdAt, id } = item;

        function isSame(item: ChatItem) {
          return item.createdAt === createdAt || item.id === id;
        }

        const isDuplicate = acc.some(isSame);

        return isDuplicate ? acc : [...acc, item];
      },
      [] as ChatItem[],
    );

    const sorted = deduplicated.sort((a, b) =>
      (a.createdAt ?? "").localeCompare(b.createdAt ?? ""),
    );

    return sorted;
  }

  async function getTranscript() {
    const isLoadingAux = isLoading;
    // Disable "End chat" button until complete transcript was fetched
    // (too slow, maybe implement lazy getTranscript or completely ignore request errors)
    setIsLoading(true);
    try {
      let transcript: Item[] = [];
      let nextToken: string | undefined = undefined;
      let response:
        | { data: GetTranscriptResponse; error: ConnectError }
        | undefined = undefined;

      do {
        response = await chatSession.current?.getTranscript({
          maxResults: 15,
          nextToken,
          sortOrder: "ASCENDING",
        });

        nextToken = response?.data.NextToken;

        transcript = [...(response?.data.Transcript ?? []), ...transcript];
      } while (nextToken);

      logChat("getTranscript", { transcript, response });

      if (!!transcript.length) {
        const deserialized =
          transcript.reduce((acc, item, index) => {
            const deserialized = deserializeAwsChatItem(item);

            // clean up duplicates
            const lastMessage = acc[index - 1];
            if (lastMessage?.content === deserialized?.content) return acc;

            return !!deserialized ? [...acc, deserialized] : acc;
          }, [] as ChatItem[]) ?? [];

        dispatchChatTranscriptReducer({
          type: "concat",
          chatTranscript: deserialized,
        });
      }
    } catch (error) {
      handleAWSConnectError(error);
    } finally {
      setIsLoading(isLoadingAux);
    }
  }

  async function sendMessage(message: string) {
    try {
      const response = await chatSession.current?.sendMessage({
        contentType: "text/plain",
        message,
      });

      if (response?.error) throw response.error;
      logChat("sendTypingEvent", response?.data);
    } catch (error) {
      handleAWSConnectError(error);
    }
  }

  async function sendTypingEvent() {
    try {
      const { data, error } = await chatSession.current?.controller.sendEvent({
        contentType: "application/vnd.amazonaws.connect.event.typing",
      });
      if (error) throw error;
      logChat("sendTypingEvent", data);
    } catch (error) {
      handleAWSConnectError(error);
    }
  }

  async function openChat() {
    const hasToken = getHasToken();

    !hasToken && (await fetchToken());
    await loadPackage();
    await createAndRetryChat();
    await getTranscript();
  }

  async function restartEndedChat() {
    dispatchChatTranscriptReducer({ type: "reset" }); // TODO: find the way to continue old conversation

    try {
      await openChat();
    } catch (error) {
      setChatError(error);
    }
  }

  function changeChatState(chatState: ChatState) {
    setChatState(chatState);
    setCookie(COOKIES_KEYS.CHAT_REFRESH_STATE, chatState);
  }

  return (
    <ChatContext.Provider
      value={{
        chatState,
        chatTranscript,
        endChat,
        isLoading,
        isOpen,
        isTyping,
        openChat,
        restartEndedChat,
        sendMessage,
        sendTypingEvent,
        setChatError,
        setIsLoading,
        setIsOpen,
        serverError,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
