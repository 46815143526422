import { AVATAR_AISHA_MCKAY_MOCK } from "src/common/mocks/avatar";
import { Agent } from "src/employee/portal/types";
import { UserType } from "src/common/types";

export const AGENT_AISHA_MCKAY_MOCK: Agent = {
  avatar: AVATAR_AISHA_MCKAY_MOCK,
  displayName: "Certified Pharmacy Technician",
  firstName: "",
  id: 5,
  lastName: "",
  role: "Member Care Team",
  type: UserType.AGENT,
};
