import { ParticipantRole } from "@aws-sdk/client-connect";
import { AGENT_AISHA_MCKAY_MOCK } from "src/common/mocks/agent";
import { logMock } from "src/common/utils/log-mock";

export function deserializeParticipantRole(
  role: ParticipantRole | string | undefined,
) {
  switch (role) {
    case ParticipantRole.AGENT:
    case ParticipantRole.CUSTOM_BOT:
    case ParticipantRole.SYSTEM:
      return logMock(AGENT_AISHA_MCKAY_MOCK.role);
    case ParticipantRole.CUSTOMER:
    case undefined:
      return "Customer";
    default:
      return role;
  }
}
